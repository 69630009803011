@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@import './reset.scss';
@import './variables.scss';

body {
	font-family: 'Lato', sans-serif;
	line-height: 1.5;
	min-width: 360px;
}
.container {
	width: 1180px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
}

.md-container {
	width: 1440px;
}

.lg-container {
	width: 1920px;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: auto;
}

h2 {
	color: $secondary;
	font-size: 55px;
	line-height: 1.2;
}

strong {
	font-weight: bold;
}

a {
	text-decoration: none;
	color: #0071c7;
}

.btn {
	display: table;
	margin: 0 auto;
	padding: 20px 40px;
	border-radius: 200px;
	text-decoration: none;
	font-size: 23px;
	font-weight: bold;
	background: $white;
	color: $secondary;
	letter-spacing: 1px;
	text-align: center;

	&:hover,
	&:focus {
		box-shadow: rgba(0, 0, 0, 0.22) 0px 5px 10px;
	}
}

.no-wrap {
	white-space: nowrap;
}

.section1 {
	background: $secondary;
	padding: 50px 0 80px 0;

	.logo {
		width: 300px;
		margin: 0 auto 60px auto;
	}

	.items {
		display: flex;
		gap: 35px;
		justify-content: center;
	}

	.item {
		width: 294px;
		background: $white;
		border-radius: 52px;
		padding: 88px 30px 66px 30px;

		.header {
			text-align: center;
			font-weight: bold;
			color: $secondary;
			font-size: 22px;
			min-height: 96px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 20px;
		}

		.price {
			text-align: center;
		}

		li + li {
			margin-top: 10px;
		}
	}

	ul {
		padding-left: 8px;
	}
	li {
		padding-left: 5px;
	}
	.check {
		ul {
			padding-left: 0;
		}

		li {
			padding-left: 24px;
			background-image: url('/src/images/check.svg');
			background-repeat: no-repeat;
			background-repeat: no-repeat;
			background-size: 16px;
			background-position: 0px 5px;
		}
	}

	.dash li::marker {
		content: '- ';
	}

	.btn {
		margin-top: 60px;
	}
}

.section2 {
	padding: 130px 0 60px 0;

	h2 {
		text-align: center;
		font-weight: 700;
	}
	p {
		text-align: center;
		color: $primary;
		font-size: 34px;
		font-weight: bold;
		margin: 40px 0;
	}

	.images-container {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 35px;

		img {
			max-height: 100px;
			object-fit: contain;
		}
	}

	.btn {
		background: $primary;
		color: $accent;
		margin-top: 30px;
	}
}

.section3 {
	padding: 60px 0 100px 0;

	.two-cols {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		gap: 60px;
	}

	h2 {
		padding-bottom: 30px;
		// font-weight: 700;
	}

	ul {
		font-size: 38px;
		color: $dark;
		list-style: disc;
		padding-left: 30px;
		font-weight: 400;
	}

	.btn {
		background: $primary;
		color: $accent;
		margin-top: 100px;
		padding: 20px 60px;
		font-size: 26px;
	}
}

.section4 {
	background: $primary;
	padding: 100px 0;
	color: $light;
	font-size: 38px;
	font-weight: 300;
	line-height: 1.3;
	// letter-spacing: 0.5px;

	.two-cols {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		gap: 60px;

		*:nth-child(3) {
			grid-column: 2;
			grid-row: 2;
		}

		*:nth-child(4) {
			grid-column: 1;
			grid-row: 2;
		}
	}

	.btn {
		color: $primary;
		padding: 20px 60px;
		margin-top: 60px;
	}
}

footer {
	margin-top: 100px;
	font-size: 18px;

	.md-container {
		border-top: 1px solid $light;
	}

	.two-cols {
		padding: 20px 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 30px;
	}

	.social {
		a {
			font-weight: bold;
			text-decoration: none;
			color: #0071c7;
		}

		span {
			font-weight: lighter;
			color: $dark;
			&::before {
				content: ' - ';
			}
		}

		i {
			padding: 0 8px 0 0;
		}

		.fa-bookmark {
			color: #cfe317;
		}

		.fa-medium {
			color: #999999;
		}

		.fa-instagram {
			color: #a143b1;
		}

		.fa-twitter {
			color: #58ccff;
		}

		.fa-facebook {
			color: #3b5998;
		}

		.fa-youtube-play {
			color: #ff0000;
		}

		li {
			margin-bottom: 5px;
		}
	}

	.privacy {
		text-align: right;

		li {
			margin-bottom: 5px;
		}
	}

	.copyrights {
		color: $dark;
		margin-top: 20px;
		font-weight: lighter;
	}
}

@media screen and (max-width: 1440px) {
	.section3 {
		h2 {
			font-size: 38px;
		}

		ul {
			font-size: 28px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.btn {
		zoom: 0.8;
	}

	h2 {
		font-size: 38px;
		line-height: 1.4;
	}

	.container {
		padding: 0 60px;
	}

	.section1 {
		.logo {
			width: 220px;
		}
		.items {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.item {
			width: 100%;
			padding: 60px 40px;
		}
	}

	.section2 {
		.images-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			max-width: 800px;
			margin: auto;
		}
	}

	.section3 {
		h2 {
			font-size: 40px;
		}

		ul {
			font-size: 22px;
		}
	}

	.section4 {
		font-size: 26px;
	}
}

@media screen and (max-width: 768px) {
	.desktop {
		display: none;
	}
	h2 {
		font-size: 28px;
	}

	.btn {
		zoom: 0.7;
		font-size: 20px;
	}

	.section1 {
		padding-bottom: 40px;

		.items {
			grid-template-columns: 1fr;
			grid-auto-rows: 1fr;
			gap: 40px;
		}
		.item {
			width: 100%;
			padding: 60px 30px;
			font-size: 16px;
			display: flex;
			flex-direction: column;
			align-content: center;
			justify-content: center;

			.header {
				text-align: left;
				min-height: auto;
			}
		}
		.item:nth-child(2),
		.item:nth-child(3),
		.item:nth-child(4) {
			padding: 0 45px;
		}
	}

	.section2 {
		padding-bottom: 20px;

		.container {
			padding: 0 40px;
		}

		p {
			font-size: 20px;
		}
		.images-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 10px;

			img:nth-child(3) {
				width: 60px;
			}
		}

		.btn {
			margin-top: 50px;
		}
	}

	.section3 {
		padding-top: 40px;
		padding-bottom: 40px;

		.container {
			padding: 0;
		}

		.text {
			margin: 0 60px;
		}

		.two-cols {
			grid-template-columns: 1fr;
			gap: 30px;
		}

		h2 {
			font-size: 26px;
			padding-bottom: 15px;
		}

		ul {
			font-size: 19px;
		}

		.btn {
			margin-top: 40px;
			font-size: 16px;
		}
	}

	.section4 {
		padding-top: 60px;
		padding-bottom: 0px;
		font-size: 19px;

		.two-cols {
			grid-template-columns: 1fr;
			gap: 20px;

			.text {
				margin-bottom: 40px;
			}

			*:nth-child(3) {
				grid-column: 1;
				grid-row: 3;
			}

			*:nth-child(4) {
				grid-column: 1;
				grid-row: 4;
			}
		}

		.btn {
			display: none;
		}
	}

	footer {
		margin-top: 0;
		.md-container {
			border: none;
		}

		.two-cols {
			grid-template-columns: 1fr;
		}
		.privacy {
			text-align: center;
		}

		.social {
			li {
				margin-bottom: 10px;
			}
			span {
				display: block;
			}
			span:before {
				content: '';
			}
		}
	}
}

@media screen and (max-width: 427px) {
	.container {
		padding: 0 30px;
	}

	.section2 .container {
		padding: 0 30px;
	}

	.section3 .text {
		margin-right: 30px;
		margin-left: 30px;
	}
}

